import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: 'main', pathMatch: 'full' },
  	{ path: 'main', loadChildren: () => import('./main/main.module').then( m => m.MainPageModule) },
	{ path: 'start', loadChildren: () => import('./main/main.module').then( m => m.MainPageModule) },
	{ path: 'gdvki', loadChildren: () => import('./gdvki/gdvki.module').then( m => m.GdvkiPageModule) }
];

@NgModule({
  	imports: [
    	RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  	],
  	exports: [RouterModule]
})
export class AppRoutingModule {}